.product {
    margin-top: 90px;

    .box {
        padding-top: 66px;

        .content {
            margin-right: 112px;
            width: 400px;
        }
        .content-right{
            margin-left 112px
            width: 400px
        }
    }
}