
.home {
    padding-bottom: 90px;
    .banner {
        height: 930px;
        background: url('../images/banner.png') no-repeat top center;
        background-size: auto 930px;
    }

    .icon-box {
        margin-top: 80px;

        li {
            margin-right: 213px;
        }

        li:last-child {
            margin-right: 0;
        }
    }
}