.information{
  padding-top: 268px;
  padding-bottom: 348px;
  display: flex;
  justify-content: center;
  .content{
    background-color: white;
    width: 62.5%;
    box-shadow: 0 6px 12px 0 rgba(0,0,0,0.1);
    border-radius: 12px;
    padding: 30px 16px 50px;
  }
}
.item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  font-size: 16px;
  color: #595959;
  font-weight: 400;
  position:relative;
  &::after{
    content:' ';
    position: absolute;
    left:0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #E8E8E8;
  }
  &:last-child::after{
    content: none;
  }
  .btn > button{
    background-image: linear-gradient(180deg, #5E7DFF 0%, #4E6EF2 100%);
    box-shadow: 0 2px 4px 0 rgba(78,110,242,0.2);
    border-radius: 16px;
    color: #FFFFFF;
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
  }
}
body.info_bg{
  background: url('../images/info_bg.png') center center/cover no-repeat;
}
.modal_container{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  .qr{
    width: 300px;
  }
}
